import { Api, BASE_URL } from '@/service/api.service';
import {
  FileService,
  FileAPIQuery,
  DocumentFileService,
} from '@/service/types';
import querystring from 'querystring';

const resource = '/files';

const overlayDocumentFilesResource = (documentId: string) =>
  `/documents/${documentId}/files`;

export const File = {
  // Get all available files
  get(query: FileAPIQuery) {
    return Api.get(`${resource}?${querystring.encode(query)}`);
  },

  downloadById(fileId: string, accept?: string) {
    return Api.get(`${resource}/${fileId}/download`, {
      responseType: 'arraybuffer',
      headers: {
        Accept: accept || '*/*',
      },
    });
  },

  // Upload a new file
  createFile(file: File, documentId: string | undefined) {
    const formData = new FormData();
    formData.append('file', file);

    if (documentId) {
      formData.append('attachment_document_id', documentId);
    }

    return Api.post(`${resource}/content`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  },

  // Delete a single file by file id
  deleteById(fileId: string) {
    return Api.delete(`${resource}/${fileId}`);
  },
} as FileService;

export const DocumentFile = {
  // Get all available files
  get(documentId: string, moduleUrlOverride?: boolean, moduleId?: string) {
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);
      return Api.get(`${overlayDocumentFilesResource(documentId)}`, {
        baseURL: `${moduleUrl}${BASE_URL}`,
        headers: {
          Authorization: `Bearer ${Api.getToken()}`,
        },
      });
    }
    return Api.get(overlayDocumentFilesResource(documentId));
  },

  downloadByDocumentIdAndFileId(
    documentId: string,
    fileId: string,
    moduleUrlOverride?: boolean,
    moduleId?: string,
  ) {
    if (moduleUrlOverride && moduleId) {
      const moduleUrl = Api.getModuleUrl(moduleId);
      return Api.get(
        `${overlayDocumentFilesResource(documentId)}/${fileId}/download`,
        {
          baseURL: `${moduleUrl}${BASE_URL}`,
          responseType: 'arraybuffer',
          headers: {
            Authorization: `Bearer ${Api.getToken()}`,
            Accept: '*/*',
          },
        },
      );
    }
    return Api.get(
      `${overlayDocumentFilesResource(documentId)}/${fileId}/download`,
      {
        responseType: 'arraybuffer',
        headers: {
          Accept: '*/*',
        },
      },
    );
  },

  // Upload a new file
  createFile(file: File, documentId: string | undefined) {
    const formData = new FormData();
    formData.append('file', file);

    if (!documentId) {
      throw new Error('documentId must be supplied');
    }

    return Api.post(
      `${overlayDocumentFilesResource(documentId)}/content`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      },
    );
  },

  // Delete a single file by file id
  deleteById(documentId: string, fileId: string) {
    return Api.delete(`${overlayDocumentFilesResource(documentId)}/${fileId}`);
  },
} as DocumentFileService;
