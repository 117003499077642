






























































import Vue from 'vue';

import { OFileManagerEmtpy } from './o-file-manager-empy.vue';

// lib
import { I8Table, I8Icon, I8FileDrop } from 'i8-ui';

// types
import { OFileManagerFile, OFileManagerAttribute } from './types';
import { OverlayFile } from '@/store';

// Icon library
import { faSpinner } from '@fortawesome/pro-light-svg-icons/faSpinner';
import { faTrashAlt } from '@fortawesome/pro-light-svg-icons/faTrashAlt';
import { faCloudDownload } from '@fortawesome/pro-light-svg-icons/faCloudDownload';
import { faCloudUpload } from '@fortawesome/pro-light-svg-icons/faCloudUpload';

// Add all icons to the library
import { library } from '@fortawesome/fontawesome-svg-core';
library.add(faSpinner, faTrashAlt, faCloudDownload, faCloudUpload);

const OFileManager = Vue.extend({
  name: 'o-file-manager',

  components: {
    I8Table,
    I8Icon,
    OFileManagerEmtpy,
  },

  directives: {
    I8FileDrop,
  },

  props: {
    files: { type: Array as () => OFileManagerFile[], default: () => [] },
    attributes: {
      type: Array as () => OFileManagerAttribute[],
      default: () => [],
    },
    isDeleted: {
      type: Function,
      default: () => () => false,
    },
    isDownloading: {
      type: Object,
      default: () => new Object(),
    },
    idColumn: { type: String, default: 'id' },
    fileUpload: { type: Boolean, default: true },
  },

  computed: {
    // compute classes for each row
    rowClasses() {
      const classes: Record<string, string> = {};

      this.files.forEach((file: OverlayFile) => {
        // Has the file been deleted?
        if (this.isDeleted(file)) {
          classes[file[this.idColumn]] = 'o-file-manager__deleted';
        }
      });

      return classes;
    },
  },

  methods: {
    // handle a file being droopped into the browser window
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    handleFileDrop(event: any): void {
      if (this.fileUpload) {
        this.$emit('new', event.dataTransfer.files);
      }
    },

    // emit download event
    downloadFile(file: File): void {
      this.$emit('download', file);
    },

    // emit delete event
    deleteFile(file: File): void {
      this.$emit('delete', file);
    },
  },
});

export { OFileManager };
export default OFileManager;
