







































import Vue from 'vue';
import { mapGetters } from 'vuex';

// components
import {
  OGlobalNav,
  OPluginSidebar,
  OToast,
  ODialog,
  OFooter,
} from '@/component';

export default Vue.extend({
  name: 'App',

  components: {
    OGlobalNav,
    OPluginSidebar,
    OToast,
    ODialog,
    OFooter,
  },

  data() {
    return {
      routeTransition: '',
    };
  },

  computed: {
    ...mapGetters('auth', ['isAuthenticated']),
    ...mapGetters('overlay', ['isReady']),
    ...mapGetters('config', ['themeName']),

    showFooter() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      // only show the footer for SaaS id8 theme
      return vm.name === 'id8';
    },
  },

  watch: {
    $route(to, from) {
      if (to.name === 'logout' && from.name != null) {
        this.routeTransition = 'slide-right';
      } else if (from.name === 'login') {
        this.routeTransition = 'slide-left';
      } else {
        this.routeTransition = '';
      }
    },
    themeName: {
      handler(newVal, oldVal) {
        if (newVal && newVal !== undefined) {
          console.log(`🎨 Theme: ${newVal}`);
          import(`i8-theme/theme-${newVal}.scss`);
          document.documentElement.classList.remove(`theme--${oldVal}`);
          document.documentElement.classList.add(`theme--${newVal}`);
        }
      },
      immediate: true,
    },
  },
});
