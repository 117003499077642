import { File, DocumentFile } from '@/service';
import { FileContext } from '@/store';
import { downloadFile, extractFileName, fileContent } from 'i8-ui';

// Create a new file
export const fileCreate = async (
  ctx: FileContext,
  { file, documentId }: { file: File; documentId: string },
): Promise<void> => {
  const response = await DocumentFile.createFile(file, documentId);
  ctx.commit('fileCreated', response.data);
};

// Load a single file by id
export const fileListLoad = async (
  ctx: FileContext,
  {
    documentId,
    moduleUrlOverride,
    moduleId,
  }: { documentId: string; moduleUrlOverride?: boolean; moduleId: string },
): Promise<void> => {
  const response = await DocumentFile.get(
    documentId,
    moduleUrlOverride,
    moduleId,
  );
  ctx.commit('fileListSet', response.data.data);
};

// Download a single file by id
export const fileByIdDownload = async (
  ctx: FileContext,
  {
    documentId,
    fileId,
    moduleUrlOverride,
    moduleId,
  }: {
    documentId: string;
    fileId: string;
    accept?: string;
    moduleUrlOverride?: boolean;
    moduleId?: string;
  },
): Promise<void> => {
  let response;
  if (!documentId) {
    response = await File.downloadById(fileId, '', moduleUrlOverride, moduleId);
  } else {
    response = await DocumentFile.downloadByDocumentIdAndFileId(
      documentId,
      fileId,
      moduleUrlOverride,
      moduleId,
    );
  }
  const filename = extractFileName(response);
  const mime = response.headers['content-type'];
  downloadFile(response.data, filename, mime);
};

// Load file content file by id
export const fileByIdContent = async (
  ctx: FileContext,
  payload: {
    fileId: string;
    documentId: string;
    accept?: string;
    moduleUrlOverride?: boolean;
    moduleId?: string;
  },
): Promise<string | ArrayBuffer | null> => {
  let response;
  if (!payload.documentId) {
    response = await File.downloadById(payload.fileId);
  } else {
    response = await DocumentFile.downloadByDocumentIdAndFileId(
      payload.documentId,
      payload.fileId,
      payload.moduleUrlOverride,
      payload.moduleId,
    );
  }
  const mime = response.headers['content-type'];
  return fileContent(response.data, mime);
};

// Open a single file by id
export const fileByIdOpen = async (
  ctx: FileContext,
  payload: {
    fileId: string;
    documentId: string;
    moduleUrlOverride?: boolean;
    moduleId?: string;
  },
): Promise<void> => {
  if (!payload.documentId) {
    throw new Error('documentId must be supplied');
  }
  const response = await DocumentFile.downloadByDocumentIdAndFileId(
    payload.documentId,
    payload.fileId,
    payload.moduleUrlOverride,
    payload.moduleId,
  );

  const filename = extractFileName(response);
  const mime = response.headers['content-type'];
  downloadFile(response.data, filename, mime, false);
};

// Delete a single file by id
export const fileByIdDelete = async (
  ctx: FileContext,
  payload: {
    fileId: string;
    documentId: string;
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const response = await DocumentFile.deleteById(
    payload.documentId,
    payload.fileId,
  );

  ctx.commit('fileDeleted', payload.fileId);
  return response;
};
