


































/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue';
import { mapActions } from 'vuex';

import {
  OAppPage,
  OPageRenderer,
  OPageConfig,
  OExtraData,
  OActions,
  ExtraDataLocalPageRequest,
} from '../../../mixin';

const SCREEN = 'document.overview';

const DocumentOverview = Vue.extend({
  mixins: [OAppPage, OPageConfig, OPageRenderer, OExtraData, OActions],

  props: {
    document: {
      type: Object,
      required: true,
    },
  },

  computed: {
    /**
     * Document ID for the docuemnt being viewed.
     *
     * This is loaded from a parameter in the URL.
     *
     * @return { string }
     */
    documentId(): string {
      return this.$route.params.id;
    },

    /**
     * Context object for the renderer.
     *
     * @return { Object }
     */
    // eslint-disable-next-line @typescript-eslint/ban-types
    context(): object {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      return {
        document: this.document,
        ...vm.extraData.data, // additional data from config
        pagination: vm.extraData.pagination,
      };
    },
  },

  methods: {
    ...mapActions('config', ['viewSchemaLoad']),
    ...mapActions('document', ['documentsByTypeLoad', 'documentByIdLoad']),

    /**
     * Load all data needed for this view.
     *
     * Includes:
     *   - i8-renderer schema used for the layout
     *   - additional data sources from the screen config
     *
     * @return { Promise<void> }
     */
    async loadData() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      vm.loadingStart();

      try {
        await vm.viewSchemaLoad({
          viewId: this.$route.name,
          parentViewId: this.$route.meta.parentId,
        });

        // load any additional data for the page
        await vm.exdLoadData(vm.dataSrc);
      } catch (error) {
        vm.loadingError(error);
      } finally {
        vm.loadingComplete();
      }
    },

    async updatePageData(payload: ExtraDataLocalPageRequest): Promise<void> {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const vm = this as any;
      vm.exdLocalPage(payload);
      await vm.exdLoadData(vm.dataSrc);
    },
  },

  watch: {
    /**
     * When the name of the route changes, load all data for this view.
     *
     * This is done in a watcher instead of a lifecycle hook to work around
     * cases where vue re-uses the component instance.
     *
     * The component instance is re-used when this view is included in the
     * `document.tabs` view
     */
    ['$route.name']: {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      handler(newVal) {
        this.loadData();
      },
      immediate: true,
    },
  },
});

export { DocumentOverview };
export default DocumentOverview;
